'use strict';

import { YandexTargetTel  } from '../utils/yandex'

function MenuTop() {

    let opt = {
        selectorBurger: '.js-burger',
        selectorWrapper: '.js-menu-wrapper',
        selectorMenu: '.js-menu',
        // selectorMenuItem: '.js-menu-item',
        selectorMenuLink: '.js-menu-link',
        selectorSubMenu: '.js-sub_menu',
        selectorUpper: '.js-menu-upper',
        selectorClose: '.js-menu-close',
        selectorBack: '.js-menu-back',
        selectorTel: '.js-tel-click',
    };

    // object_id пункта меню для показа дополнительного меню в адаптиве
    let ids = [104,];

    let $mainMenu = $(opt.selectorMenu);
    let $upperMenu = $(opt.selectorUpper);

    $(opt.selectorBurger).on('click', function(e){
        e.preventDefault();
        showMainMenu(true);
    });

    $(opt.selectorClose).on('click', function(e){
        e.preventDefault();
        showMainMenu(false);
    });

    $(opt.selectorBack).on('click', function(e){
        if($upperMenu.hasClass('m--active')){
            e.preventDefault();
            showUpperMenu(false);
        }
    });

    $(opt.selectorMenuLink).on('click', function(e){

        if ($(opt.selectorWrapper).hasClass('m--active') && $(this).hasClass('is-span')){
            let id = $(this).data("id") || 0;

            if (ids.indexOf(id) != -1) {
                showUpperMenu(true, this);
            }
            e.preventDefault();
        }
    });

    function showMainMenu(show){

        if(show){
            $(opt.selectorWrapper).addClass('m--active');
            $(opt.selectorBurger).addClass('m-hidden');
            $($mainMenu).show();
            $(opt.selectorBack).hide();

        }
        else {
            $(opt.selectorWrapper).removeClass('m--active');
            $(opt.selectorBurger).removeClass('m-hidden');
            $(opt.selectorUpper).removeClass('m--active').html('');
        }
    }

    function showUpperMenu(show, obj) {

        let $self = $(obj);
        let $subMenu = $self.next(opt.selectorSubMenu);

        if(show && $subMenu && !$upperMenu.hasClass('m--active')){
            $upperMenu.append($subMenu.html()).show().addClass('m--active');
            $mainMenu.hide();
            $(opt.selectorBack).show();
        }
        else {
            $(opt.selectorUpper).html('').removeClass('m--active');
            $($mainMenu).show();
            $(opt.selectorBack).hide();
        }
    }

    $(opt.selectorTel).on('click', function(){

       YandexTargetTel();

    });
}

export {
    MenuTop
};
