'use strict';

function Offer(){
    let paginator_counter = $('.content__paginator-counter'),
        offers = $('.content__offer').filter('.content__offer');

    let opt = {
        // selectorOffers: '.js-offers',
        selectorNext: '.js-offers-next',
        selectorPrev: '.js-offers-prev',
        selectorCurrent: '.js-offers-current',
        selectorItem: '.js-offers-item',
        selectorTotal: '.js-offers-total'
    }

    let  $items = $(opt.selectorItem),
         current = Number($(opt.selectorCurrent).text()),
         total = Number($(opt.selectorTotal).text());

    current = 1;
    total = $(opt.selectorItem).length;

    $(opt.selectorNext).on('click', function(e){
        e.preventDefault();

        if (current < total ) {
            current ++;
            $items.eq(current - 1).show().prev().hide();
        }
        else {
            current = 1;
            $items.last().hide();
            $items.eq(current - 1).show();
        }
        changePaginator(current, total);
    });

    $(opt.selectorPrev).on('click', function(e){
        e.preventDefault();

        if (current == 1 ) {
            current = total;
            $items.first().hide();
            $items.eq(current - 1).show();
        }
        else {
            current--;
            $items.eq(current - 1).show().next().hide();
        }
        changePaginator(current, total);
    });

    function changePaginator(current, total){
        let $current = $(opt.selectorCurrent),
            $total = $(opt.selectorTotal);

        $current.text(current);
        $total.text(total);
    }
}

export {
    Offer
};
