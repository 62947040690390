'use strict';

/* global $ */

function detectSessionID() {
    let sessionId = '';
    if (window.location.toString().match(/SESS_ID=(\d+)/)) {
        sessionId = RegExp.$1;
    }
    return sessionId;
}

let fancyboxDefault = {
    padding: 0,
    helpers: {
        title: { type: 'inside' }
    }
};

let fancybox = {
    padding: 0,
    closeBtn: false,
    maxWidth: 960,
    helpers: {
        title: { type: 'inside' },
        overlay : {
            css : {
                'background' : 'rgba(0, 0, 0, 0.3)'
            }
        }
    }
};

let fancyboxBlue = {
    padding: 0,
    wrapCSS: 'flexites-fancy',
    closeBtn: false,
    helpers: {
        title: { type: 'inside' },
        overlay : {
            css : {
                'background' : 'rgba(29, 62, 129, 0.95)'
            }
        }
    }
};
let fancyboxForm = {
    padding: 0,
    wrapCSS: 'flexites-fancy',
    // closeBtn: false,
    helpers: {
        title: { type: 'inside' },
        overlay : {
            css : {
                // 'background' : 'rgba(255, 255, 255, 0)'
                'background' : 'rgba(93, 184, 253, 0.68)'
                // 'opacity' : '1'
            }
        }
    }
};



let ajax = {
    DATA_TYPE: 'json',
    URI: '/json/',
    METHOD: 'post',
    TIMEOUT: 5000,
    SESSION_ID: detectSessionID()
};

let $body = $(document.body);

let interfaceName = $body.data('interface_name');

let isTablet = !!parseInt($body.data('is_tablet'));
let isMobile = !!parseInt($body.data('is_mobile'));
let isDesktop = !isMobile && !isTablet;

let breakpoints = {
    xl: Infinity,
    l: 1367,
    m: 1000,
    s: 720,
    xs: 320
};

export {
    interfaceName,
    isTablet,
    isMobile,
    isDesktop,
    breakpoints,
    ajax,
    fancyboxDefault,
    fancybox,
    fancyboxBlue,
    fancyboxForm,
    // slickCenter,
    // slick
};
