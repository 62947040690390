'use strict';

/* global $ */
import { YandexTargetEmail  } from '../utils/yandex'

function Map(options) {

    let opt = $.extend({
        selector: '.js-map',
        mapSelector: '.js-map-content',
        itemSelector: '.js-map-item',
        titleSelector: '.js-map-title',
        addrSelector: '.js-map-addr',
        selectorEmail: '.js-email-click',
        mapOptions: {
            zoom: 15,
            center: [56.778658, 60.612297], //default coord center
            controls: ['geolocationControl', 'zoomControl']
        }
    }, options);

    function initMap(el) {
        let $el = $(el),
            $map = $el.find(opt.mapSelector),
            $item = $el.find(opt.itemSelector),
            item = {};

            $map.coords = $map.data('coords').split(/[^0-9.]+/);
            opt.mapOptions['center'] = $map.coords;

        let map = new ymaps.Map($map.get(0), opt.mapOptions, {suppressMapOpenBlock: true});

        item.title = $item.find(opt.titleSelector).html();
        item.addr = $item.find(opt.addrSelector).html();


        let placemark = new ymaps.Placemark($map.coords, {
            balloonContentHeader: item.title,
            balloonContentBody: item.addr
        }, {
            preset: 'islands#darkBlueIcon'
        });

        map.geoObjects.add(placemark);


    //     function showItem(i) {
    //         $items.removeClass('is-active').eq(i).addClass('is-active');
    //         // map.setCenter($items.eq(i).data('coords'), opt.mapOptions.zoom);
    //         map.panTo([$items.eq(i).data('coords')], { delay: 0, safe: false, flying: false });
    //     }

    //     // $link.on('click', function(e) {
    //     //     e.preventDefault();
    //     //     showItem($link.index(this));

    //     // });

    //     // showItem(0);
    }

    ymaps.ready(function() {
        $(opt.selector).each(function() {
            initMap(this);
        });
    });

     $(opt.selectorEmail).on('click', function(){

       YandexTargetEmail();

    });
}

export {
    Map
};
