"use strict";

import { Responsive } from './proj/responsive.js';
import { MenuTop } from './proj/menu.js';
// import { Footer } from './proj/footer';
import { Offer } from './proj/offer.js';
import { Map } from './proj/map.js';
import { Educators } from './proj/educators.js';
import { Groups, TableChange } from './proj/groups.js';
import { Form } from './proj/form.js';
import { Homepage } from './proj/homepage.js';
import { About } from './proj/about.js';
import { Faq } from './proj/faq.js';

var Proj = function (){

};

Proj.prototype.Responsive = Responsive;
Proj.prototype.MenuTop = MenuTop;
// Proj.prototype.Footer = Footer;
Proj.prototype.About = About;
Proj.prototype.Offer = Offer;
Proj.prototype.Map = Map;
Proj.prototype.Educators = Educators;
Proj.prototype.Groups = Groups;
Proj.prototype.Form = Form;
Proj.prototype.Homepage  = Homepage;
Proj.prototype.Faq  = Faq;
Proj.prototype.TableChange = TableChange;


global.Proj = Proj;
