'use strict';

function YandexTargets(){
}

function YandexTargetOpen(){
    if(window.yaCounter49895689){
        yaCounter49895689.reachGoal('form_open');
    }
}

function YandexTargetSend(){
    if(window.yaCounter49895689){
        yaCounter49895689.reachGoal('form_send');
    }
}

function YandexTargetTel(){
    if(window.yaCounter49895689){
        yaCounter49895689.reachGoal('call_now');
    }
}

function YandexTargetEmail(){
    if(window.yaCounter49895689){
        yaCounter49895689.reachGoal('mail_send');
    }
}

export {
    YandexTargetOpen,
    YandexTargetSend,
    YandexTargetTel,
    YandexTargetEmail  
};
