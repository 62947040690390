'use strict';

/* global $ */

import { breakpoints } from '../utils/params';

function Responsive(options) {
    let opt = $.extend(true, {
        breakpoints: breakpoints
    }, options);

    let $window = $(window),
        currentPoint = undefined;

    function watchSize() {
        let width = $window.width(),
            point = 'xs';

        if (width > opt.breakpoints.l) {
            point = 'xl';
        } else if (width > opt.breakpoints.m) {
            point = 'l';
        } else if (width > opt.breakpoints.s) {
            point = 'm';
        } else if (width > opt.breakpoints.xs) {
            point = 's';
        }

        if (!currentPoint || point != currentPoint) {
            if (currentPoint) {
                $window.trigger('Responsive.off.'+currentPoint);
            }

            $window.trigger('Responsive.on.'+point);

            currentPoint = point;

            watchMenu(currentPoint);
            watchTable(currentPoint);
        }
    }

    function watchMenu(currentPoint){
        let is_active = false;
        let opt = {
            selectorMenu: '.js-menu',
            selectorMenuWrapper: '.js-menu-wrapper',
        }
        let $menu = $(opt.selectorMenu);

        if((currentPoint == 'xl' || currentPoint == 'l') && $menu.hasClass('m--small')){
            $menu.removeClass('m--small');
        }
    }
    function watchTable(currentPoint){
        let $table = $('.js-timetable');
        if(currentPoint == 's' || currentPoint == 'xs'){
            $('.js-timetable-more').each(function(index){
                $(this).parent().hide();
                $('.js-timetable-link').eq(index).attr('href', $(this).attr('href'));
            });
        }
        else {
            $('.js-timetable-more').parent().show();
            $('.js-timetable-link').removeAttr('href');
        }
    }

    $window.on('resize', watchSize);
    watchSize();
}

export {
    Responsive
};

