"use strict";

/* global $ */

// import { slick } from '../utils/params';
import { fancyboxDefault, breakpoints } from '../utils/params';


function Groups(options) {

    let opt = $.extend(true, {
        breakpoints: breakpoints,
        selectorCarousel: '.js-carousel',
        selectorNext: '.js-carousel-next',
        selectorPrev: '.js-carousel-prev',
        selectorFancy: '.js-fancy-gallery',
        selectorLink: '.js-timetable-link',

    }, options);

    let slickParams = {
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        infinite: true,
        // adaptiveHeight: true,
        variableWidth: true,
        centerMode: true,
        cssEase: 'linear',
        arrows: false,
        marginBottom: true,

        responsive: [
            {
              breakpoint: 1201,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                infinite: true,
                marginBottom: false
              },
            },
        ]
    }

    let fancyboxGallery = {
        afterShow:function(){
            $(".fancybox-next").on("click", function() {
                $slickSlider.slick('slickNext');
            });
            $(".fancybox-prev").on("click", function() {
                $slickSlider.slick('slickPrev');
            });
        },
        padding: 0,
        maxWidth: 1024,
        helpers: {
            title: { type: 'inside' }
        }
    };

    let $slickSlider = $(opt.selectorCarousel);

    $slickSlider.on('init', function(event, slick){
        if (slick.slickGetOption('marginBottom')){
            $('.slick-center').addClass('slick-try');
        }
    });

    $slickSlider.slick(slickParams);

    $slickSlider.on('afterChange', function(event, slick, currentSlide, nextSlide){
        if (slick.slickGetOption('marginBottom')){
            $('.slick-slide').removeClass('slick-try');
            $('.slick-active').addClass('slick-try');
        }
    });

    $(opt.selectorPrev).on('click', function(e){
        e.preventDefault();
        $slickSlider.slick('slickPrev');
    });

    $(opt.selectorNext).on('click', function(e){
        e.preventDefault();
        $slickSlider.slick('slickNext');
    });

    $(opt.selectorFancy).fancybox(fancyboxGallery);
}

function TableChange(options){

    let opt = $.extend(true, {
        selectorFace : '.timetable__face',
        selectorBefore : '.timetable__faces-before',
        selectorAfter : '.timetable__faces-after',
        selectorTableWrapper: '.js-table-wrapper',

    }, options);

    let $tables = $(opt.selectorTableWrapper);

    $(opt.selectorFace).on('click', function(){
        let $self = $(this);
        let __active = false,
            __index = $self.index();

        if ($self.hasClass('m--active')){
            __active = true;
        }
        else {
            __active =true;
            $self.addClass('m--active').siblings().removeClass('m--active');
            if($tables.eq(__index).length) {
                let $tableWrapper = $tables.eq(__index);
                $tableWrapper.addClass('m--active').siblings().removeClass('m--active');
            }

            if (__index == 0){
                $(opt.selectorBefore).addClass('m--active');
            }
            else if (__index == 4){
                $(opt.selectorAfter).addClass('m--active');
            }
            else {
                $(opt.selectorBefore).removeClass('m--active');
                $(opt.selectorAfter).removeClass('m--active');
            }
        }
    });
}

export {
    Groups,
    TableChange
};

