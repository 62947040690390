"use strict"; 

function Homepage() {
    let opt = {
        selectorAnimate: '.js-animated',
    }


    function changeState(obj){
        let $blocks = $(opt.selectorAnimate);
        let array = randImg();
        $blocks.each(function(index){
            let $self = $(this);

            if(array[index] == 1){
                $self.addClass('m--active');
            }
            else {
                $self.removeClass('m--active');
            }
        });
    }

    function randImg(){
        let opt = {
            countImg: 4,
        }

        let array = [];
        let min = 1,
            max = 3,
            randItem = 0;

        let active =  Math.floor(Math.random() * (max - min + 1)) + min;

        for (let i = 0; i < opt.countImg; i++){
            array[i] = 0;
        }

        for (let i = 0; i < active; i++){
            let index = 0;
            index = recursion(array, genRand(0, 3));
            if (index != 'undefined') {
                array[index] = 1;
            }
        }
        return array;
    }

    function recursion(array, i){
        if (array[i] == 0){
            return i;
        } else {
            let error = 1;
            for (let l = 0; l < array.length; l ++){
                if (array[l] == 0) {
                    error = 0;
                }
            }
            if (error == 1) {
                return "ERROR";
            }
            else {
                recursion(array, genRand(0,3));
            }
        }
    }

    function genRand(min, max){
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    let retArr = randImg();
    setInterval(changeState, 4000);
}

export {
    Homepage
};
