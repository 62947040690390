'use strict';

function Faq() {

    let opt = {
        selectorFaq: '.js-faq',
        selectorQuestion: '.js-faq-question',
        selectorTitle: '.js-faq-title',
        selectorAnswer: '.js-faq-answer',
    };

    if($(opt.selectorFaq).hasClass('is-slider')){
        $(opt.selectorTitle).on('click', function(e){
            let $self = $(this);
            let $question = $(this).parent(opt.selectorQuestion);
            $question.siblings().children(opt.selectorAnswer).slideUp(400, function(){
                $(this).parent(opt.selectorQuestion).removeClass('m--active');
                $self.next(opt.selectorAnswer).slideDown(600);
                $question.addClass('m--active');
            });
            e.preventDefault();
        })
    }
}

export {
    Faq
};
