'use strict';

/* global $ */

import { getAjax } from '../utils/ajax';
import { fancyboxForm, fancyboxBlue, fancyboxDefault } from '../utils/params'
import { YandexTargetOpen, YandexTargetSend  } from '../utils/yandex'

let settings = {
    formSelector: '.js-form',
    linkSelector: '.js-form-link',
    fieldSelector: '.js-form-field',
    inputSelector: 'input, select, textarea',
    selectorRes: '.js-form-result',
    selectorCheckbox: '.js-checkbox-label',
    selectorFormContent: '.js-form-content',
    errorClass: 'is-error',
};

function Form(options) {

    let opt = $.extend(true, {}, settings, options);

    let $checkbox = $(opt.selectorCheckboxWrapper);

    $(document.body).on('click', opt.linkSelector, function(e) {
        e.preventDefault();

        let $el = $(this);
        let form_href = this.getAttribute('href');
        let params = $.extend({}, $el.data(), { form_name: form_href }), options;
        YandexTargetOpen();

        GetForm(params);
    });

    $(document.body).on('submit', opt.formSelector, function(e) {
        e.preventDefault();
        SubmitForm(this);
    });

    $(opt.formSelector).each(function() {
        InitForm(this);
    });
}

function InitForm(el, options) {

    let $form = $(el);
    let opt = $.extend(true, {}, options);
    let $fields = $form.find(settings.fieldSelector);

    $fields.filter('.js-has-dependent').on('change', settings.inputSelector, function() {
        let $el = $(this);
        let fields = $el.parents(settings.fieldSelector).data('dependent').split(/\s+/);

        for (let f of fields) {
            $fields.filter('.m--name-'+f).find(settings.inputSelector).addClass('is-loading');
        }

        let params = $form.serializeObject();
        params._fields = fields;

        getAjax('get_form_values', params).then(function(response) {
            let data = response.data;

            for (let f of fields) {
                let $input = $fields.filter('.m--name-'+f).find(settings.inputSelector);

                $input.removeClass('is-loading');

                if (data[f]) {
                    let value = $input.xval();
                    $input.replaceWith(data[f]);
                    $input.xval(value);
                }
            }
        }).catch(function(error) {
            alert('При отправке формы произошла ошибка');
        });
    });

    $fields.filter('.m--name-captcha').remove();
}

function GetForm(params, options) {
    getAjax('get_form', params).then(function(response) {
        let data = response.data;
        let style = params.form_style;

        if (data.ok) {
            ShowForm(data.content, options, style);
        } else {
            alert('Не удалось получить форму');
        }
    }).catch(function(error) {
        alert('При получении формы произошла ошибка');
        console.log(error);
    });
}

function ShowForm(content, options, style) {
    let opt = $.extend({}, settings, options);

    if (style === 'blue-bg') {
        $.fancybox($.extend(true, {}, fancyboxBlue, options, {
            type: 'html',
            content: content,
            afterShow: function() {
                this.inner.find(opt.formSelector).each(function() {
                    InitForm(this);
                });
            }
        }));
    } else {
        $.fancybox($.extend(true, {}, fancyboxForm, options, {
            type: 'html',

            content: content,
            afterShow: function() {
                this.inner.find(opt.formSelector).each(function() {
                    InitForm(this);
                });
            }
        }));
    }
}

function updateForm(form, content, options){
    let opt = $.extend({}, settings, options);
    let res = form.children(opt.selectorRes);
    form.children(opt.selectorRes).html(content).addClass('m--active');

    form.children(opt.selectorFormContent).remove();
}

function SubmitForm(el, options) {
    let $form = $(el);

    let opt = $.extend({}, settings, options);

    $form.find(opt.selectorCheckbox).removeClass('is-error');
    let $fields = $form.find(opt.fieldSelector);
    $fields.filter('.'+opt.errorClass).removeClass(opt.errorClass);

    let params = $form.serializeObject();

    getAjax('send_form', params).then(function(response) {
        let data = response.data;
        if (data.ok) {
            updateForm($form, data.content);
            YandexTargetSend();
       } else if (data.errors) {
           console.log(data.errors);
            for (let p in data.errors) {
                $fields.filter('.m--name-'+p).addClass(opt.errorClass);
                if (p == 'agreement'){
                    $form.find(opt.selectorCheckbox).addClass(opt.errorClass);
                }
            }
        }
    }).catch(function(error) {
        console.log(error);
        alert('При отправке формы произошла ошибка');
    });
}

export {
    Form
};
