"use strict";

/* global $ */

import { fancybox } from '../utils/params';
// import { owlParams } from '../utils/params';

function Educators(options) {

    let opt = $.extend({
        selectorSertImg: '.js-fancy',

        selectorLink: '.js-sert-link',
        selectorSert: '.js-sert',
        selectorTextShow: '.js-sert-text-show',
        selectorTextHide: '.js-sert-text-hide',
        selectorSlick: '.js-slick',
        selectorNext: '.js-slick-next',
        selectorPrev: '.js-slick-prev',
        selectorSertWrapper: '.js-sert-wrapper',
    }, options);

    let slickParams = {
        slidesToShow: 2,
        slidesToScroll: 1,
        draggable: true,
        infinite: true,
        adaptiveHeight: true,
        arrows: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 720,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },

        ]

    }

    $(opt.selectorSert).each(function(index){
        let width = $(this).parent(opt.selectorSertWrapper).width();
        // $(this).width(width);
        if (!$(this).hasClass('m--small')) width -= 100;
        $(this).children(opt.selectorSlick).width(width).css('max-width',width).slick(slickParams);
    });


    $(opt.selectorLink).on('click', function(e){
        let $self = $(this);
        let $sert = $self.prev(opt.selectorSert);
        let slickSlider = $sert.children(opt.selectorSlick).attr('id');
        let width = $sert.parent().width();

        if (!$sert.hasClass('m--active')){
            // $sert.animate({
            //     height: "140px",
            //     opacity: 1,
            // }, 300, function() {
            //    // Animation complete.
            // }).addClass('m--active');
            $sert.addClass('m--active');
            $self.children(opt.selectorTextHide).addClass('m--active ').siblings().removeClass('m--active');
        }
        else {
            // $sert.animate({
            //     height: "0",
            //     opacity: 0,
            // }, 300, function() {
            //    // Animation complete.
            // }).removeClass('m--active');
            $sert.removeClass('m--active');

            $self.children(opt.selectorTextShow).addClass('m--active ').siblings().removeClass('m--active');
        }
        e.preventDefault();
    });

    $(opt.selectorNext).on('click', function(e){
        e.preventDefault();
        let $self = $(this);
        let slickSlider = '#' + $self.prev(opt.selectorSlick).attr('id');
        let next = $(slickSlider).slick('slickNext');
    });
    $(opt.selectorPrev).on('click', function(e){
        e.preventDefault();
        let $self = $(this);
        let slickSlider = '#' + $self.next(opt.selectorSlick).attr('id');
        $(slickSlider).slick('slickPrev');
    });
}

export {
    Educators
};
