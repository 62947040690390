'use strict';

import axios from '../lib/axios';

import { ajax } from './params';

function getAjax(act, data, options) {
    if (ajax.SESSION_ID) {
        data['SESS_ID'] = ajax.SESSION_ID;
    }

    let url = ajax.URI;
    let method = ajax.METHOD;

    if (options) {
        if (options.url) {
            url = options.url;
            delete options['url'];
        }
        if (options.method) {
            method = options.method;
            delete options['method'];
        }
    }

    url = url + act + '/';

    if (method === 'post') {
        return axios.post(url, JSONToURLEncoded(data), options);
    } else {
        return axios.get(url, { params: data }, options);
    }
}

function JSONToURLEncoded(data) {
    let params = new URLSearchParams();

    if (data instanceof Array) {
        for (let val of data) {
            params.append(val.name, val.value);
        }
    } else {
        for (let key in data) {
            params.append(key, data[key]);
        }
    }

    return params;
}

export { getAjax };
