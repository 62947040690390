'use strict';

function About() {

    let opt = {
        selectorLink: '.js-documents-link',
        selectorDocuments: '.js-documents',
    };

    $(opt.selectorLink).on('click', function(e){
        e.preventDefault();
        let $documents = $(this).next(opt.selectorDocuments);
        if (!$documents.hasClass('m--active')){
            $documents.slideDown().addClass('m--active');
            // $documents.addClass('m--active');
        } else {
            $documents.slideUp().removeClass('m--active');
            // $documents.removeClass('m--active');
        }
    });
}

export {
    About
};
